.backoffice-users-main-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.backoffice-users-page-content {
    width: 100%;
    flex: 1 1;
    overflow-y: auto;
    scrollbar-width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    gap: 40px;
}

.backoffice-users-page-content::-webkit-scrollbar {
    width: 10px;
}

.backoffice-users-page-content::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.backoffice-users-page-content::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--dialog-background);
    background-clip: content-box;
}

.backoffice-users-page-content::-webkit-scrollbar-thumb:hover{
    background-color: var(--text);
}