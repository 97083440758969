.dropzone-main-div {
    width: 150px;
    height: 150px;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
}

.dropzone-main-div:hover,
.dropzone-main-div:focus {
    width: 175px;
    padding: 20px;
    filter: drop-shadow(2px 4px 6px black);
    outline: none;
}

.dropzone-main-div>p {
    text-align: center;
    font-size: 14px;
    white-space: pre-line;
}

.dropzone-main-div.dragging {
    animation: dragging 5s ease-in-out infinite;
    width: 175px;
    padding: 20px;
    filter: drop-shadow(2px 4px 6px black)
}

.dropzone-main-div.image {
    border: none;
}

.image-preview {
    width: 150px;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}

.image-remove-icon {
    color: var(--text);
    cursor: pointer;
}

.icon-image-preview-div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon-image-preview-div>p {
    font-size: 14px;
    text-align: center;
}

@keyframes dragging {
    0% {
        background-color: var(--background);
    }

    50% {
        background-color: var(--text);
    }

    100% {
        background-color: var(--background);
    }
}