.backoffice-user-card-main-div {
    width: 250px;
    height: 350px;
    margin-top: 75px;
    position: relative;
    padding: 110px 0 0px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background);
    border-radius: 10px;
    box-shadow: 1px 1px 8px 0 #000000;
    animation: card-rise 1s ease-in-out;
}

.backoffice-user-card-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: absolute;
    top: -50px;
    overflow: hidden;
    border: 5px solid var(--background);
    box-shadow: 0px 6px 6px 0px #00000073;
}

.backoffice-user-card-image>img {
    height: 100%;
    width: 100%;
}

.backoffice-user-card {
    width: 100%;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px 10px;
    box-shadow: inset 0px 10px 15px -8px black;
    border-radius: 10px;
    overflow: hidden;
    max-height: 60px;
    opacity: 0;
    animation: card-inner 0.8s 1s ease-in-out forwards;
}

.backoffice-user-card-top-info {
    text-align: center;
}

.backoffice-user-card-top-info :last-child {
    font-size: 14px;
    color: var(--secondary);
    font-weight: 600;
}

.backoffice-user-card-bottom-info {
    flex: 1 1;
    text-align: center;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    max-height: 131px;
}

.backoffice-user-card-actions {
    display: flex;
    justify-content: space-evenly;
}

.backoffice-user-card-actions>svg {
    color: var(--text);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.backoffice-user-card-actions>svg:hover {
    scale: 1.1;
}

@keyframes card-inner {
    0% {
        opacity: 0;
        max-height: 60px;
    }

    100% {
        max-height: 250px;
        opacity: 1;
    }
}

@keyframes card-rise {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

}