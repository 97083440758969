.view-more-app-user-main-div {
    width: 100%;
    height: 600px;
    display: flex;
}

.view-more-app-user-left-div {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.view-more-app-user-right-div {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 10px 10px;
    gap: 10px;
}

.view-more-user-card-image {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    border: 2px solid var(--secondary);
    object-fit: cover;
}

.view-more-user-company-img {
    width: auto;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    cursor: pointer;

}

.view-more-user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    gap: 10px;
    margin-bottom: auto;
}

.view-more-user-meta {
    font-size: 14px;
    text-align: center;
}

.view-more-app-projects-list {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    padding: 10px;
}

.tab-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
}