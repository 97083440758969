.company-card-div {
    width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.company-card-div:hover {
    scale: 1.1;
}