.reset-password-main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background);
    height: 100vh;
}

.reset-password-form {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.reset-password-buttons {
    display: flex;
    justify-content: space-around;
}

.reset-password-top-text {
    margin-bottom: 25px;
    text-align: center;
}

.reset-pass-user-name {
    font-size: 22px;
}

.reset-pass-theme-toggle {
    position: absolute;
    bottom: 10px;
}