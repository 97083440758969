.decompress-progress-div {
    background-color: var(--dark-background);
    box-shadow: 0px 0px 5px 1px var(--secondary);
    width: 400px;
    height: 200px;
    padding: 10px;
    position: absolute;
    top: 15px;
    border-radius: 20px;
    right: 15px;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    transition: all 0.3s ease-in-out;
}

.decompress-progress-div.show {
    height: 200px;
    opacity: 1;
}

.decompress-progress-div.hide {
    height: 0;
    opacity: 0;
    pointer-events: none;
}


.decompress-progress-div>p {
    text-align: center;
}

.progress-bar {
    margin-top: -10px;
    width: 90%;
    height: 15px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 2px 0 var(--text);
}

.inner-progress-bar {
    height: 100%;
    background-color: var(--secondary);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}