.roles-settings-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.roles-settings-title {
    width: 100%;
    font-size: 22px;
    font-weight: 600;
    margin-left: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--text);
}

.roles-settings-bottom {
    display: flex;
    height: 75%;
    width: 100%;
    gap: 5px
}

.roles-selector {
    height: 100%;
    width: 30%;
    overflow-y: auto;
    border: 1px solid white;
    border-radius: 10px;
    box-shadow: 2px 2px 7px 0 rgb(0 0 0 / 50%);
}

.roles-info {
    flex: 1 1;
    height: 100%;
    overflow-y: auto;
    border: 1px solid white;
    border-radius: 10px;
    color: white;
    box-shadow: 2px 2px 7px 0 rgb(0 0 0 / 50%);
    scrollbar-width: auto;
}

.role-card {
    width: 100%;
    height: fit-content;
    padding: 0 10px;
    text-align: center;
    min-height: 40px;
    word-break: break-all;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--grey);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    animation: optionRise 0.8s ease-in-out;
}

.role-card.active {
    background-color: var(--secondary);
    color: white;
    width: 100%;
    height: fit-content;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
    letter-spacing: 1px;
}

.role-card:hover {
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
    letter-spacing: 1px;
}

.role-permissions-main-div {
    height: fit-content;
    position: relative;
}

.role-permissions-title {
    font-size: 26px;
    word-break: break-all;
    max-width: 330px;
    color: var(--text);
}

.role-permissions-title-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 0 15px;
}

.role-permissions-actions {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.role-permissions-actions>svg {
    color: var(--table-buttons);
    cursor: pointer;
}

.roles-category {
    display: flex;
    flex-direction: column;
    margin: 10px 0 10px 20px;
}


.role-category-title {
    font-weight: 500;
    color: var(--text);
}

.info-dialog-action {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--text);
    padding: 10px 0; 
}

.info-dialog-text-div {
    width: 80%;
}

.info-dialog-text-div>p {
    margin-bottom: 10px;
    color: var(--dark-pink);
    font-size: 16px;
    text-align: center;
}

.roles-settings-subtitle {
    margin-bottom: 10px;
    color: var(--text);
    font-size: 16px;
}

@keyframes optionRise {
    0% {
        transform: translateY(400px);
    }

    100% {
        transform: translateY(0px);
    }
}

.roles-info::-webkit-scrollbar {
    width: 10px;
}

.roles-info::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.roles-info::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--dialog-background);
    background-clip: content-box;
}

.roles-info::-webkit-scrollbar-thumb:hover {
    background-color: var(--secondary);
}