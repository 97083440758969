.select-arrow {
    rotate: 90deg;
    scale: 1.3;
    color: var(--text);
    position: absolute;
    right: 7px;
    top: 22px;
    background-color: transparent;
    cursor: pointer;
}

.clear-icon {
    scale: 1;
    color: var(--text);
    position: absolute;
    right: 37px;
    top: 24px;
    cursor: pointer;
    background-color: transparent;
}