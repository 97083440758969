.section-main-div {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.section-content {
    height: 300px;
    /* border: 1px solid var(--secondary); */
    border-radius: 10px;
    box-shadow: var(--textfield-shadow);
    background-color: var(--background);
    padding: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-content.large {
    width: 100%;
}

.section-content.medium {
    width: 66%;
}

.section-content.small {
    flex: 1 1;
    min-width: 30%;
}