.table-card-div {
    height: 90px;
    width: 250px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    animation: table-card 0.8s ease-in-out forwards;
    box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 50%);
    cursor: pointer;
}

.table-card-div:hover {
    transform: translateY(-6px);
    box-shadow: 4px 4px 4px 1px rgb(0 0 0 / 50%);
}

.table-card-info {
    height: 50px;
    background-color: var(--background);
    box-shadow: 0px 1px 4px 0px black;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.table-card-actions {
    height: 35px;
    background-color: var(--background);
    box-shadow: 0px -1px 4px 0px black;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.table-preview {
    flex: 1 1;
    width: 100%;
    overflow: hidden;
}

.table-card-actions>svg:hover {
    cursor: pointer;
    color: var(--secondary);
}

/* --------------- Table preview --------- */

.table-div-preview {
    font-size: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    user-select: none;
}

.table-headers-preview {
    display: flex;
    justify-content: flex-start;
}

.table-p-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;
}

.table-rows-wrapper-preview {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.table-row-preview {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 5px;
    align-items: center;
    margin-right: 5px;
}

.index-col-preview {
    width: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.table-sub-row-div-preview {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    padding: 2px;
    border-left: 1px solid var(--secondary);
    border-radius: 5px;
    position: relative;
}

.table-sub-row-preview {
    display: flex;
    border: 1px solid var(--table-buttons);
    border-radius: 10px;
    box-shadow: 2px 2px 2px 0 rgb(0 0 0 / 50%);
    min-height: 15px;
    transition: all 0.2s ease-in-out;
}

@keyframes table-card {
    0% {
        height: 90px;
    }

    100% {
        height: 300px;
    }
}