.app-user-small-card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
    transition: all 0.2s ease-in-out;
    user-select: none;
    cursor: pointer;
    border-radius: 20px;
    animation: app-user-card 0.3s ease-in-out;
}

.app-user-small-card:hover {
    transform: translateY(-5px);
}

@keyframes app-user-card {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* --------------- Dashboard User card ---------------- */

.dashboard-user-card {
    max-width: 250px;
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.dashboard-user-card:hover {
    scale: 1.1;
}

.dashboard-user-card-name {
    margin-top: 20px;
    font-weight: 700;
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.dashboard-user-card-email {
    font-size: 14px;
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    text-align: center;
}