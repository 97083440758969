.name-and-picture-div {
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 200px;
}

.url-component {
    color: var(--secondary);
    text-decoration: underline;
    cursor: pointer;
}