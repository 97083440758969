* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "JetBrains Mono", monospace;
	-ms-overflow-style: none;
	/* Internet Explorer 10+ */
	scrollbar-width: none;
	/* Firefox */
}

::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

:root {
	--light-black: #171717;
	--dark-red: #a31900;
	--light-red: #ff7056;
	--grey: #a3a3a3;
	--light-grey: #d9d9d9;
	--dark-grey: #6a6a6a;
	--side-bar-width: 60px;
	--top-bar-height: 75px;
}

body.light {
	--background: #d9d9d9;
	--text: #171717;
	--login-logo-invert: 0;
	--login-page-lines: rgba(0, 0, 0, 0.1);
	--login-page-line: #000000;
	--textfield-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #bdbdbd 0 -3px 0 inset;
	--textfield-shadow-hover: rgb(82 82 82) 0 2px 4px, rgb(45 35 66 / 30%) 0 7px 13px 0px, #bdbdbd 0 -3px 0 inset;
	--textfield-shadow-error: var(--dark-red) 0 2px 4px, rgb(45 35 66 / 30%) 0 7px 13px 0px, #bdbdbd 0 -3px 0 inset !important;
	--textfield-shadow-border: var(--dark-red);
	--pass-btn: #6a6a6a;
	--button-active-shadow: rgb(82 82 82) 0 0px 3px -3px, rgb(45 35 66 / 30%) 0 3px 5px 0px, #bdbdbd 0 -3px 0 inset;
	--toggle-background: #d9d9d9;
	--dialog-background: #d9d9d9cc;
	--dark-background: #ffffff;
	--table-buttons: #636363;
	--textfield-error:#a31900; 
	--secondary: #1976d2;
	--statistics-background: #007fff40;
}

body.dark {
	--background: #2a2a2a;
	--text: #d9d9d9;
	--login-logo-invert: 1;
	--login-page-lines: rgba(255, 255, 255, 0.1);
	--login-page-line: #ffffff;
	--textfield-shadow: rgb(0 0 0 / 40%) 0 2px 4px, rgb(0 0 0 / 30%) 0 7px 13px -3px, #2a2a2a 0 -3px 0 inset;
	--textfield-shadow-hover: rgb(34 25 25 / 59%) 0 2px 4px, rgb(0 0 0) 0 2px 10px 0px, #2a2a2a 0 -3px 0 inset;
	--textfield-shadow-error: #360800 0 2px 4px, rgb(45 35 66 / 30%) 0 7px 13px 0px, #481616 0 -3px 0 inset !important;
	--textfield-shadow-border: var(--light-red);
	--pass-btn: #d9d9d9;
	--button-active-shadow: rgb(82 82 82) 0 0px 3px -3px, rgb(45 35 66 / 30%) 0 3px 5px 0px, #2a2a2a 0 -3px 0 inset;
	--toggle-background: #141414;
	--dialog-background: #2a2a2acc;
	--dark-background: #121212;
	--table-buttons: #d9d9d9;
	--textfield-error:#ff7056;
	--secondary: #ed6c02;
	--statistics-background: #b1500040;
}