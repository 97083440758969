.projects-list-wrapper {
    height: 450px;
    overflow-y: auto;
    scrollbar-width: auto;
}

.projects-search-div {
    height: 75px;
    width: 100%;
    padding: 10px 50px;
    display: flex;
    gap: 20px;
}

.projects-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 10px;
}

.projects-list-actions {
    width: 100%;
}

.projects-list-wrapper::-webkit-scrollbar {
    width: 10px;
}

.projects-list-wrapper::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.projects-list-wrapper::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--dialog-background);
    background-clip: content-box;
}

.projects-list-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: var(--secondary);
}