.side-bar-items-div {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    gap: 10px;
    width: 100%;
}

.side-bar-button {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    padding: 10px 0 10px 18px;
    text-align: center;
}

.side-bar-button.active,
.side-bar-button:hover {
    background-color: rgba(0, 0, 0, 0.5);
    color: #d9d9d9;
}

.side-bar-button.active > svg,
.side-bar-button:hover > svg {
    color: #d9d9d9;
}

.side-bar-button>svg {
    scale: 1.3;
    margin-right: 30px;
    color: var(--text)
}

.side-bar-button>p {
    margin-top: 5px;
}