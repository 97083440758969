.loading-button-main-div {
	align-items: center;
	outline: none;
	appearance: none;
	border-width: 0;
	box-shadow: var(--textfield-shadow);
	box-sizing: border-box;
	color: var(--text);
	cursor: pointer;
	display: inline-flex;
	font-family: "JetBrains Mono", monospace;
	justify-content: center;
	line-height: 1;
	list-style: none;
	overflow: hidden;
	position: relative;
	text-align: left;
	text-decoration: none;
	transition: box-shadow .15s, transform .15s;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	white-space: nowrap;
	will-change: box-shadow, transform;
}

.loading-button-main-div.small {
	height: 30px;
	min-width: 0px;
	font-size: 16px;
	padding: 2px 20px;
	border-radius: 5px;
}

.loading-button-main-div.medium {
	height: 40px;
	min-width: 100px;
	font-size: 18px;
	padding: 2px 20px;
	border-radius: 10px;
}

.loading-button-main-div.large {
	height: 50px;
	min-width: 140px;
	font-size: 20px;
	padding: 2px 30px;
	border-radius: 10px;
}


.button-loading-div {
	width: 100%;
	height: 100%;
	display: flex;
	gap: 10px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.loading-button-main-div:disabled {
	opacity: 0.3;
	cursor: not-allowed;
}

.loading-button-main-div:not([disabled]):focus {
	box-shadow: var(--textfield-shadow-hover);
	transform: translateY(-2px);
}

.loading-button-main-div:not([disabled]):hover {
	box-shadow: var(--textfield-shadow-hover);
	transform: translateY(-2px);
}

.loading-button-main-div:not([disabled]):active {
	box-shadow: var(--button-active-shadow);
	transform: translateY(2px);
}