.settings-dialog-content {
    width: 100%;
    height: 600px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
}

.settings-dialog-body {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px 0;
}

.settings-body-menu {
    height: 100%;
    width: 25%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.settings-body-right-content {
    height: 100%;
    flex: 1 1;
}

.settings-menu-button {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s ease-in-out;
}

.settings-menu-button.active {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    letter-spacing: 1px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    color: white;
    background-color: var(--secondary);
}

.settings-menu-button:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    letter-spacing: 1px;
}

.body-right-roles {
    height: 100%;
    width: 100%;
    padding: 10px 20px 0px 20px;
}