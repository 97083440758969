.project-small-card-div {
    width: 100%;
    max-width: 500px;
    height: 100px;
    display: flex;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 1px 1px 2px 0 var(--light-black);
    background-color: var(--background);
    animation: projectCard 0.3s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.project-small-card-div:hover {
    transform: translateY(-2px);
    box-shadow: 3px 3px 3px -1px var(--secondary);
}

.project-small-card-image-div {
    height: 100%;
    width: 35%;
    box-shadow: 0px 2px 6px 0px var(--light-black);
}

.project-small-card-image-div>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: zoom-in;
    transition: all 0.1s ease-in-out;
}

.project-small-card-image-div>img:hover {
    filter: saturate(1.5);
}

.project-small-card-info {
    flex: 1 1;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.project-small-card-name {
    font-size: 18px;
    font-weight: 700;
}

.projects-small-card-company-div {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.project-small-card-company-name {
    font-size: 12px;
}

.projects-small-card-company-div > img {
    height: 100%;
    width: auto;
}

.projects-small-card-stats {
    margin-top: auto;
    display: flex;
    gap: 20px;
}

@keyframes projectCard {
    0% { opacity: 0 }
    100% { opacity: 1 }
}