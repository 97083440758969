.share-projects-main-div {
    width: 100%;
    padding-bottom: 20px;
}

.share-projects-tab-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    text-align: center;
}