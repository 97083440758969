.top-bar-main-div {
    width: 100vw;
    right: 0;
    height: 75px;
    background-color: var(--background);
    position: fixed;
    display: flex;
    align-items: center;
    padding: 0 25px;
    justify-content: space-between;
    z-index: 200;
    box-shadow: 0px 4px 6px 0 rgb(0 0 0 / 50%);
}


.top-panel-user-display {
    min-height: 40px;
    max-width: 40px;
    width: fit-content;
    justify-content: space-between;
    color: var(--text);
    box-shadow: 0px 0px 0px 2px var(--text);
    display: flex;
    align-items: center;
    padding-right: 15px;
    border-radius: 25px;
    user-select: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    animation: user_display 2s ease-in-out forwards;
    animation-delay: 1s;
}

.top-panel-user-display>p {
    opacity: 0;
    height: 0;
    animation: user_display_p 0.5s 2s ease-in-out forwards;

}

.top-panel-user-display:hover {
    box-shadow: 0px 0px 5px 5px var(--pass-btn);
}


.top-bar-logo-div {
    height: 40px;
    width: fit-content;
    position: relative;
    display: flex;
    align-items: center;
    margin-right: auto;
}

.top-bar-logo {
    width: auto;
    height: 100%;
    filter: invert(var(--login-logo-invert));
}

.top-bar-theme-toggle {
    margin: 0 40px;
}

@keyframes user_display_p {
    0% {
        opacity: 0;
        height: 0;
    }

    100% {
        opacity: 1;
        height: 21px;
    }
}

@keyframes user_display {
    0% {
        max-width: 40px;
    }

    100% {
        max-width: 400px;
    }
}