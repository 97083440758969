.custom-radio-main-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.custom-radio-item {
    display: flex;
    align-items: center;
}

.custom-radio-item > p {
    font-size: 14px;
}