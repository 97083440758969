.general-settings-main-div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding-right: 10px;
    scrollbar-width: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.general-settings-block {
    width: 100%;
    height: fit-content;
    position: relative;
    border-radius: 20px;
    box-shadow: 0px 3px 3px 0 rgb(0 0 0 / 50%);
    display: flex;
    gap: 10px;
    padding: 20px 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.general-settings-block-title {
    position: absolute;
    top: -15px;
    left: 15px;
    font-size: 20px;
}

.general-settings-block-subtitle {
    align-self: baseline;
    font-size: 14px;

    margin: -10px 0 10px 10px
}

.general-settings-visible-columns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.general-settings-block-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 10px 0;
    gap: 5px;

}

.general-settings-main-div::-webkit-scrollbar {
    width: 10px;
}

.general-settings-main-div::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.general-settings-main-div::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--dialog-background);
    background-clip: content-box;
}

.general-settings-main-div::-webkit-scrollbar-thumb:hover {
    background-color: var(--secondary);
}