.project-card-dashboard {
    max-width: 400px;
    width: 30%;
    position: relative;
    gap: 5px;
    display: flex;
    flex-direction: column;
}

.project-card-dashboard>p {
    text-align: center;
}

.project-card-title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 10px;
}

.project-card-company-div {
    position: absolute;
    bottom: 70px;
    right: 10px;
    height: 50px;
    width: auto;
    pointer-events: none;
    filter: drop-shadow(0px 0px 3px white);
}

.projects-view-more-image-dashboard {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 3px 3px 5px 0 rgb(0 0 0 / 50%);
    cursor: zoom-in;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.projects-view-more-image-dashboard:hover {
    border: 4px solid white;
}