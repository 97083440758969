.qrcode-main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.qrcode-link {
    text-align: center;
    color: var(--secondary);
    font-size: 12px;
    cursor: pointer;
}

.qrcode-link:hover {
    text-decoration: underline;
}