.textfield-main-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.textfield-input-input.helper-text {
    margin-bottom: 0;
}

.helper-text-p {
    color: var(--text);
    min-height: 20px;
    font-size: 12px;
    padding-left: 5px;
    margin-top: 5px;
}

.textfield-input-input.error ~ .helper-text-p {
    color: var(--textfield-error);
}

.helper-text-action {
    min-height: 20px;
    font-size: 14px;
    padding-left: 10px;
    cursor: pointer;
    color: var(--text);
}

.show-pass-button {
    position: absolute;
    right: 10px;
    top: 24px;
    cursor: pointer;
}

.helper-text-action:hover {
    text-decoration: underline;
}

.textfield-label {
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: capitalize;
    font-size: 16px;
    color: var(--text)
}

.textfield-input-input {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    letter-spacing: 1px;
    font-weight: 300;
    border: none;
    outline: none;
    transition: box-shadow 0.2s ease-in-out;
    padding-left: 10px;
    color: var(--text);
    box-shadow: var(--textfield-shadow);
}

.textfield-endIcon {
    position: absolute;
    top: 2px;
    right: 5px;
}

.textfield-endIcon > svg:hover {
    cursor: pointer;
    scale: 1.1
}

.textfield-input-input:disabled {
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
}

.textfield-input-input.error {
    box-shadow: var(--textfield-shadow-error);
    border: 1px solid var(--textfield-shadow-border);
}

.textfield-input-input:hover {
    box-shadow: var(--textfield-shadow-hover);
}

.textfield-input-input:focus-visible {
    box-shadow: var(--textfield-shadow-hover);
}
