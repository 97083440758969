.table-actions-div {
    display: flex;
    justify-content: space-evenly;
    gap: 7px;
    height: 24px;
}

.table-actions-div > svg {
    color: var(--table-buttons);
    cursor: pointer;
    transition: scale 0.2s ease-in-out;
}

.table-actions-div > svg:hover {
    scale: 1.1;
}