.forgot-pass-main-div {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.forgot-pass-btn {
    align-self: center;
    margin-top: 10px;
}

.forgot-pass-text {
    color: var(--text);
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
}