.profile-main-div {
    height: 500px;
    width: 100%;
    display: flex;
    gap: 10px;
    overflow: hidden;
    padding: 10px 0;
}

.profile-edit-div {
    height: 100%;
    transition: all 0.3s ease-in-out;
    width: 50%;
    display: flex;
    flex-direction: column;
}


.profile-info-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    flex: 1 1;
    padding: 10px ;
    transition: all 0.3s ease-in-out;
}

.profile-image-div {
    position: relative;
    display: flex;
    justify-content: center;
}

.profile-image-div.dragging > .profile-portrait-image {
    scale: 1.05;
    opacity: 0.7;
}

.profile-image-div.dragging > .image-upload-info {
    opacity: 1;
}

.profile-portrait-image {
    height: 175px;
    width: 175px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 2px 2px 10px 0px rgb(0 0 0 / 50%);
    border: 3px solid white;
    transition: all 0.2s ease-in-out;
}

.profile-portrait-image:hover {
    scale: 1.05;
    opacity: 0.7;
}

.profile-portrait-image:hover ~ .image-upload-info,
.profile-portrait-image:hover ~ .remove-picture-btn {
    opacity: 1;
}

.profile-portrait-image ~ .remove-picture-btn:hover {
    opacity: 1;
    scale: 1.1;
}

.profile-user-role {
    padding: 2px 10px;
    background-color: var(--secondary);
    border-radius: 20px;
    color: white;
    margin-top: -15px;
    z-index: 10;
    box-shadow: 2px 2px 10px 0px rgb(0 0 0 / 50%);
}

.profile-user-info {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    flex: 1 1;
}

.image-upload-info {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    top: 25px;
    font-size: 18px;
    padding: 5px 20px;
    text-align: center;
    text-shadow: 1px 1px 3px black;
    transition: all 0.2s ease-in-out;
}

.remove-picture-btn {
    position: absolute;
    pointer-events: visible;
    opacity: 0;
    bottom: 20px;
    cursor: pointer;
    filter: drop-shadow(0px 0px 6px black);
    transition: all 0.3s ease-in-out;
}

.remove-picture-btn:hover {
    scale: 1.1;
}


.edit-profile-form-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 440px;
    padding: 10px 0;
    gap: 10px;
    width: 92%;
    animation: profile-edit-form 0.3s ease-in-out;
}


@keyframes profile-edit-form {
    0% { opacity: 0;}
    100% { opacity: 1;}
}