.search-input-main-div {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 20px;
}

.search-input-icon {
    position: absolute;
    left: 10px;
    top: 5px;
}

.search-input-close {
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
}

.search-input-input {
    width: 100%;
    min-width: 250px;
    height: 35px;
    border: 2px solid white;
    background-color: transparent;
    color: white;
    text-indent: 50px;
    outline: none;
    border-radius: 7px;
    transition: all 0.3s ease-in-out;
}

.search-input-main-div:hover .search-input-input,
.search-input-input:focus {
    min-width: 300px;
}

.search-input-input::placeholder {
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
}