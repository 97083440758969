.dialog-prefab-wrapper {
    width: 100%;
    border-radius: 10px;
    position: relative;
}

.dialog-outer-div .MuiPaper-root {
    border-radius: 10px;
    background-color: transparent;
}

.dialog-prefab-close-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    z-index: 100;
    transition: all 0.2s ease-in-out !important;
}

.dialog-prefab-close-icon:hover {
    scale: 1.1;
}

.dialog-prefab-title-div {
    background-color: var(--background);
    height: 35px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    box-shadow: 0px 2px 2px 0 rgb(0 0 0 / 50%);
}

.dialog-prefab-title {
    color: var(--text);
    user-select: none;
}

.dialog-prefab-background-div {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -1;
    background-color: var(--dialog-background);
    backdrop-filter: blur(3px);
}