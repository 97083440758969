.error-dialog-main-div {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.error-dialog-info-div {
    margin-bottom: 20px;
    white-space: pre-wrap;
}

.error-dialog-top-info {
    margin-bottom: 30px;
    text-align: center;
    font-size: 20px;
}

.error-dialog-top-info > p {
    font-size: 20px;
    color: var(--text);
}

.error-dialog-description-div {
    font-size: 14px;
    color: var(--text);
    text-align: center;
    margin-bottom: 10px;
    overflow-x: auto;
    scrollbar-width: auto;
}

.error-dialog-description-div::-webkit-scrollbar {
    width: 10px;
}

.error-dialog-description-div::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.error-dialog-description-div::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--dialog-background);
    background-clip: content-box;
}

.error-dialog-description-div::-webkit-scrollbar-thumb:hover{
    background-color: var(--secondary);
}