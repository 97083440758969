.dashboard-main-div {
    height: calc(100vh - var(--top-bar-height));
    width: calc(100vw - var(--side-bar-width));
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    gap: 40px;
}

.dashboard-top-div {
    width: 90%;
    display: flex;
    justify-content: space-around;
    border-radius: 20px;
    box-shadow: var(--textfield-shadow);
    background: var(--background);
}

.dashboard-top-div-el {
    width: 25%;
    max-width: 350px;
    min-width: 250px;
    height: 170px;
    border-radius: 10px;
    transition: scale 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55), transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55), box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* box-shadow: 0px 0px 10px 0px var(--secondary); */
    padding: 10px;
    overflow-x: hidden;
    animation: dashboard-top-panel 0.3s ease-in-out;
    cursor: pointer;
}

.dashboard-top-div-el:hover {
    scale: 1.1;
    transform: translateY(20px);
    box-shadow: var(--textfield-shadow);
    background-color: var(--dark-background);
}

.dashboard-top-panel-title {
    text-align: left;
    width: 100%;
    font-size: 20px;
    margin-left: 10px;
    animation: dashboard-top-title 0.5s ease-in-out;
    text-transform: uppercase;
    letter-spacing: 6px;
}

.dashboard-top-panel-value {
    font-size: 36px;
    text-transform: uppercase;
    text-align: right;
    width: 100%;
    margin-right: 20px;
    animation: dashboard-top-value 0.5s ease-in-out;
}

.dashboard-last-projects {
    width: 100%;
    max-height: 300px;
    min-height: 200px;
    height: 35%;
    border-radius: 10px;
    transition: box-shadow 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    box-shadow: var(--textfield-shadow);
    background: var(--background);
    position: relative;
    padding-top: 30px;
}

.dashboard-last-projects>p {
    text-align: left;
    width: 100%;
    padding-left: 40px;
    font-size: 20px;
    position: absolute;
    top: 10px;
}

.dashboard-bottom-sections {
    width: 100%;
    max-height: 300px;
    min-height: 200px;
    height: 33%;
    border-radius: 10px;
    transition: box-shadow 0.3s ease-in-out;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
}

.dashboard-bottom-users,
.dashboard-bottom-companies {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px 20px 20px;
    border-radius: 10px;
    box-shadow: var(--textfield-shadow);
    background: var(--background);
}

.dashboard-users-inner,
.dashboard-companies-inner {
    flex: 1 1;
    display: flex;
    justify-content: space-evenly;
}

.dashboard-last-project-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.dashboard-top-div-el-chart {
    width: 100%;
    height: 85px;
    padding-top: 10px;
}

@keyframes dashboard-top-title {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes dashboard-top-value {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes dashboard-top-panel {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        ;
    }
}