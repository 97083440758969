.view-more-table-main-div {
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
}

.view-more-table-company-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.view-more-table-actions {
    position: absolute;
    display: flex;
    gap: 5px;
    align-items: center;
    height: 50px;
    top: 40px;
}

.table-div {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.table-headers {
    display: flex;
    justify-content: flex-start;
    margin-right: 20px;
}

.table-row {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 15px;
    align-items: center;
    margin-top: 10px;
}

.table-sub-row-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    padding: 20px 5px 5px;
    border-left: 2px solid var(--secondary);
    border-radius: 15px;
    position: relative;
}

.table-sub-row-div-actions {
    position: absolute;
    padding: 0 10px;
    top: -10px;
    left: 10px;
    display: flex;
    gap: 10px;
}

.table-sub-row-div-actions > svg {
    cursor: pointer;
    scale: 0.8;
}

.table-sub-row-div-actions > svg:hover {
    cursor: pointer;
    scale: 1;
    color: var(--secondary);
}

.table-sub-row {
    display: flex;
    padding: 5px 0;
    border: 1px solid var(--table-buttons);
    border-radius: 10px;
    box-shadow: 2px 2px 2px 0 rgb(0 0 0 / 50%);
    min-height: 36px;
    transition: all 0.2s ease-in-out;
}

.table-sub-row.filled:hover {
    border: 1px solid var(--secondary);
    border-radius: 15px;
    box-shadow: 3px 3px 4px 0 rgb(0 0 0 / 50%);
}

.table-sub-row > p {
    margin: auto;
}

.table-p {
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;
}

.index-col {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.table-rows-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    scroll-behavior: smooth;
    height: 550px;
    scrollbar-width: auto;
    padding-right: 10px;
}

.table-sub-row-actions {
    width: 225px;
    display: flex;
    justify-content: center;
    align-items: center; 
    gap: 10px;
}

.table-row-actions {
    width: 50px;
    height: 95%;
    max-height: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 5px 0;
    border: 1px solid var(--table-buttons);
    margin-right: -5px;
    margin-left: 5px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 0 rgb(0 0 0 / 50%);
}

.table-row-actions > svg:hover {
    color: var(--secondary);
    cursor: pointer;
}

.table-actions-icon {
    cursor: pointer;
    color: var(--table-buttons);
    transition: all 0.2s ease-in-out;
}

.table-actions-icon:hover {
    scale: 1.1;
    color: var(--secondary);
}


.table-rows-wrapper::-webkit-scrollbar {
    width: 10px;
}

.table-rows-wrapper::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.table-rows-wrapper::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--dialog-background);
    background-clip: content-box;
}

.table-rows-wrapper::-webkit-scrollbar-thumb:hover{
    background-color: var(--secondary);
}