.users-list-wrapper {
    height: 75%;
    overflow-y: auto;
    scrollbar-width: auto;
    padding-left: 10px;
}

.users-search-div {
    height: 75px;
    width: 100%;
    padding: 10px 40px;
    position: relative;
}

.users-clear-search-icon {
    position: absolute;
    color: var(--text);
    z-index: 20;
    right: 13%;
    bottom: 36px;
    cursor: pointer;
}

.users-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}

.users-list-wrapper::-webkit-scrollbar {
    width: 10px;
}

.users-list-wrapper::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.users-list-wrapper::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--dialog-background);
    background-clip: content-box;
}

.users-list-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: var(--secondary);
}