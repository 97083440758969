.statistics-main-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 40px 0;
    gap: 40px;
    overflow-y: auto;
    scrollbar-width: auto;
}

.statistics-section {
    width: 90%;
    background: linear-gradient(180deg, var(--statistics-background) 0px, transparent 200px);
    border-radius: 20px;
    padding: 20px 10px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.statistics-section>p {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: var(--table-buttons)
}


.statistics-main-div::-webkit-scrollbar {
    width: 10px;
}

.statistics-main-div::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.statistics-main-div::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--dialog-background);
    background-clip: content-box;
}

.statistics-main-div::-webkit-scrollbar-thumb:hover {
    background-color: var(--secondary);
}

.visualizations-map-and-devices {
    background-color: #f7f7f717;
    box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.5);
}