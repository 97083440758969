.toggle-button {
    color: white;
    border: none;
    border-radius: 0;
    padding: 2px 7px;
}

.toggle-button:hover {
    background-color: rgb(255,255,255,0.2);
}

.toggle-button.selected {
    background-color: white;
    color: var(--dark-blue);
    font-weight: 600;
    cursor: default;
    border: none;
    border-radius: 0;
    padding: 2px 7px;
}

.toggle-button.selected:hover {
    background-color: white;
}