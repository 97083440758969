.projects-page-main-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
}

.projects-table-div {
    margin: 50px 0;
}