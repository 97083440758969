.project-view-more-main-div {
    width: 100%;
    height: 600px;
    display: flex;
    gap: 20px;
    padding: 10px;
}

.projects-view-more-users-list-wrapper {
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.projects-view-more-users-list {
    width: 100%;
    height: 85%;
}

.projects-view-more-shared {
    width: 100%;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.shared-with-users-avatar {
    width: 100%;
    flex: 1 1;
    overflow-y: auto;
    scrollbar-width: auto;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.projects-view-more-info {
    padding-top: 25px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.projects-view-more-users-title {
    text-align: center;
    margin-bottom: 10px;
}

.project-view-more-image-div {
    position: relative;
    width: 75%;
    display: flex;
    justify-content: center;
}

.project-view-more-online-div {
    position: absolute;
    top: 0;
    width: 100%;
    height: 20px;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
}

.projects-view-more-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 3px 3px 5px 0 rgb(0 0 0 / 50%);
    cursor: zoom-in;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.projects-view-more-image:hover {
    height: 175px;
}

.project-view-more-title {
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
}

.projects-view-more-actions {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.projects-view-more-actions>svg {
    cursor: pointer;
}

.project-view-more-company-div {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.view-more-info-project-files {
    font-size: 14px;
    text-align: center;
}