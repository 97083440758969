/* ----------Delete dialog---------- */
.delete-dialog-div {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.delete-dialog-div>p {
    text-align: center;
    white-space: pre-wrap;
}

.dialog-confirm-pass-and-button {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 80%;
}

/* ----------Add dialog---------- */
.add-dialog-div {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-dialog-title {
    text-align: center;
}

.add-dialog-user-fields {
    width: 80%;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.add-dialog-url-preview {
    margin-bottom: 25px;
    text-align: center;
}

.add-dialog-url-preview :nth-child(2) {
    font-size: 12px;
    cursor: copy;
    transition: all 0.2s ease-in-out;
    text-transform: lowercase;
}

.add-dialog-url-preview :nth-child(2):hover {
    background-color: var(--secondary);
}

/* ----------Edit dialog---------- */

.edit-project-bottom-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* ----------View More dialog---------- */



/* ----------Tables dialog---------- */

.add-table-number-of-projects-div {
    width: 120%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.add-table-inner-projects-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 5px 10px;
    border-radius: 10px;
    box-shadow: var(--textfield-shadow);
    max-width: 250px;
}

.add-dialog-joined-fields {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 40px;
}