.login-page-main-div {
    width: 100vw;
    height: 100vh;
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 425px;
	overflow-x: hidden;
}

.login-page-form {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex: 1 1;
    z-index: 20;
    padding: 40px 0;
    animation: login-form 0.7s ease-in-out;
}

.login-page-logo-div {
    position: relative;
    width: 100%;
    height: 320px;
    display: flex;
    justify-content: center;
}

.login-page-logo {
    width: 40%;
    min-width: 400px;
    max-width: 600px;
    height: 100%;
    filter: invert(var(--login-logo-invert));
    animation: login-logo 0.7s ease-in-out;
}

.logo-line {
    position: absolute;
    width: 100%;
    height: 1px;
    overflow: hidden;
    background: var(--login-page-lines);
}

.logo-line::after {
    content: '';
    display: block;
    position: absolute;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    height: 100%;
    width: 100px
}

.logo-line.top {
    top: 99px;
}

.logo-line.bottom {
    bottom: 100px;
}

.logo-line.top::after {
    animation: line1 8s 0s infinite;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, var(--login-page-line) 75%, var(--login-page-line) 100%);
    left: -50%;
}

.logo-line.bottom::after {
    animation: line2 8s 0s infinite;
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, var(--login-page-line) 75%, var(--login-page-line) 100%);
    right: -50%;
}

.login-textfield {
    width: 100%;
}

.login-textfield-pass {
    width: 100%;
}

.login-forgot-pass-link {
    color: var(--text);
    z-index: 30;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.login-forgot-pass-link:hover,
.login-forgot-pass-link:focus {
    text-decoration: underline;
    outline: none;
}

.login-bottom-div {
    height: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(100%);
    justify-content: space-evenly;
    animation: login-bottom 0.4s 0.8s ease-in-out forwards;
}


@keyframes line1 {
    0% {
        left: -50%
    }

    100% {
        left: 150%
    }
}

@keyframes line2 {
    0% {
        right: -50%
    }

    100% {
        right: 150%
    }
}


@keyframes login-logo {
    0% {
        transform: translateX(-1000px)
    }

    100% {
        transform: translateX(0)
    }
}

@keyframes login-form {
    0% {
        transform: translateX(1000px)
    }

    100% {
        transform: translateX(0)
    }
}

@keyframes login-bottom {
    0% {
        transform: translateY(100%)
    }

    100% {
        transform: translateY(0)
    }
}