.side-bar-div {
    padding-top: var(--top-bar-height);
    animation: itemsRollUp 1s ease-in-out; 
    position: fixed;
    height: 100vh;
    width: var(--side-bar-width);
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background);
    box-shadow: 4px 0px 4px 0px rgb(0 0 0 / 40%);
    z-index: 100;
    overflow-x: hidden;
    transition: all 0.2s ease-in-out;
}

.side-bar-div:hover {
    width: 200px;
}

@keyframes itemsRollUp {
    0% { padding-top: 1000px }
    100% { padding-top: 75px;}
}