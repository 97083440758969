.users-visits-div {
    width: 100%;
    height: 100%;
}

.users-visits-title {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 5px;
}

.users-visits-table-wrapper {
    height: 250px;
    overflow-y: auto;
    border-radius: 10px;
    padding: 0 10px;
}

.users-visits-table {
    width: 100%;
}

.users-visits-table-head {
    position: sticky;
    top: 0px;
    color: var(--background);
    background-color: var(--secondary);
    text-align: center;
}

.users-visits-table-body {
    text-align: center;
}

.users-table-row:hover {
    cursor: pointer;
    color: var(--background);
    background-color: var(--secondary); 
}


.users-table-row:hover {
    cursor: pointer;
    background-color: var(--secondary); 
}

.users-visits-table-wrapper::-webkit-scrollbar {
    width: 10px;
}

.users-visits-table-wrapper::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.users-visits-table-wrapper::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--dark-background);
    background-clip: content-box;
}

.users-visits-table-wrapper::-webkit-scrollbar-thumb:hover{
    background-color: var(--secondary);
}