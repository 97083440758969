.view-all-tables-main-div {

}

.view-all-tables-content {
    min-height: 320px;
    max-height: 500px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    scrollbar-width: auto;
    margin: 10px;
    padding: 20px;
}



.view-all-tables-content::-webkit-scrollbar {
    width: 10px;
}

.view-all-tables-content::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.view-all-tables-content::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--dialog-background);
    background-clip: content-box;
}

.view-all-tables-content::-webkit-scrollbar-thumb:hover{
    background-color: var(--secondary);
}