.company-view-more-div {
    width: 100%;
    height: 600px;
    display: flex;
    padding: 10px;
    min-width: 800px;
}

.company-view-more-left {
    width: 35%;
    height: 100%;
}

.company-view-more-left > p {
    font-size: 22px;
    font-size: 700;
    text-align: center;
    margin-bottom: 10px;
}

.company-view-more-center {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px 10px;
    gap: 10px;
}

.company-view-more-right {
    width: 35%;
    height: 100%;
}

.company-view-more-right > p {
    font-size: 22px;
    font-size: 700;
    text-align: center;
    margin-bottom: 10px;
}

.company-view-more-title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.company-view-more-company-img {
    height: 75px;
    width: auto;
}

.company-view-more-url:hover {
    color: var(--secondary);
    text-decoration: underline;
    cursor: pointer;
}