.users-line-chart {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.users-chart-top-info {
    width: 100%;
    height: fit-content;
    display: flex;
    padding-left: 20px;
    justify-content: space-between;
}

.users-chart-top-info-title {
    font-size: 15px;
    padding-top: 10px;

}

.users-chart-time-piker {
    display: flex;
    gap: 10px;
    width: 350px;
}

.users-chart-div {
    flex: 1 1;
    width: 100%;
}